import React from "react";
import Countdown from "react-countdown";
import { motion } from "framer-motion";
import "./CountdownTimer.css";

const CountdownTimer = () => {
  const Completionist = () => <span>Offer has expired!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className="timer">
          <motion.div
            className="time-block"
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            {days} Days
          </motion.div>
          <motion.div
            className="time-block"
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            {hours} Hours
          </motion.div>
          <motion.div
            className="time-block"
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            {minutes} Minutes
          </motion.div>
          <motion.div
            className="time-block"
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 1, repeat: Infinity }}
          >
            {seconds} Seconds
          </motion.div>
        </div>
      );
    }
  };

  const now = Date.now();
  const fifteenDaysFromNow = now + 1 * 24 * 60 * 60 * 1000;

  return <Countdown date={fifteenDaysFromNow} renderer={renderer} />;
};

export default CountdownTimer;
