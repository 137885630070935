import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import console2successLogo from "../assets/console2suucessLogo.png";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  useEffect(() => {
    // Check if the script is already added
    if (!window.isRazorpayScriptAdded) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.setAttribute("data-payment_button_id", "pl_OauVa0iX5tbbTZ");
      script.async = true;
      document.getElementById("razorpay-container").appendChild(script);
      window.isRazorpayScriptAdded = true; // Set the flag to indicate script is added
    }
  }, []);

  const Hamburger = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="24"
      viewBox="0 0 52 24"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 47)"
          fill="#fff"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="42"
          height="4"
          rx="2"
          transform="translate(304 67)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="52"
          height="4"
          rx="2"
          transform="translate(294 57)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo-container">
          <img
            className="logo"
            src={console2successLogo}
            alt="console2success"
          />
        </div>
        <div className="social-media">
          <a
            href="https://x.com/Console2Success?t=w8Ajav2UlNO4PMoPSVpGuw&s=08"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/console2success?igsh=eTNkaDd6dHJkdTAw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
          </a>
          <a
            href="https://youtube.com/@console2success?si=Gz4VqzI5keA-Q9ac"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="social-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/console2success/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn className="social-icon" />
          </a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/" onClick={handleShowNavbar}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={handleShowNavbar}>
                Contact
              </NavLink>
            </li>
            <li>
              {/* <button
                type="button"
                className="btn btn-primary btn-sm"
                data-mdb-ripple-init=""
              >
                <form action="" id="razorpay-container"></form>
              </button> */}
              <form action="" id="razorpay-container"></form>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
