import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Navbar from "./components/Header";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsConditions from "./pages/TermsConditions";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}>
            Home
          </Route>
          <Route path="/contact" element={<Contact />}>
            Home
          </Route>
          <Route path="/refund-policy" element={<RefundPolicy />}>
            Refund Policy
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}>
            Refund Policy
          </Route>
          <Route path="/terms-conditions" element={<TermsConditions />}>
            Refund Policy
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
