import React from "react";

const PrivacyPolicy = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const heading1Style = {
    color: "#2c3e50",
    fontSize: "2.5em",
    marginBottom: "20px",
    textAlign: "center",
  };

  const heading2Style = {
    color: "#3498db",
    fontSize: "1.5em",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    lineHeight: "1.6",
    marginBottom: "20px",
    color: "#666",
  };

  const listStyle = {
    listStyleType: "disc",
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#666",
  };

  const listItemStyle = {
    marginBottom: "10px",
  };

  const strongStyle = {
    color: "#333",
  };

  const contactStyle = {
    color: "#e74c3c",
    fontWeight: "bold",
  };

  return (
    <>
      <div style={containerStyle}>
        <h1 style={heading1Style}>Privacy Policy</h1>
        <p style={paragraphStyle}>Effective Date: 30 July 2024</p>

        <h2 style={heading2Style}>1. Introduction</h2>
        <p style={paragraphStyle}>
          Welcome to Console2Success. We value your privacy and are committed to
          protecting your personal information. This Privacy Policy explains how
          we collect, use, disclose, and safeguard your information when you
          visit our website console2success.in, including any other media form,
          media channel, mobile website, or mobile application related or
          connected thereto.
        </p>

        <h2 style={heading2Style}>2. Information We Collect</h2>
        <p style={paragraphStyle}>
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Personal Data:</strong> Personally
            identifiable information, such as your name, email telephone number.
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Derivative Data:</strong> Information
            our servers automatically collect when you access the Site, such as
            your IP address, browser type, operating system, access times, and
            the pages you have viewed directly before and after accessing the
            Site.
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>
              Cookies and Tracking Technologies:
            </strong>{" "}
            We use cookies, web beacons, and other tracking technologies to
            enhance your experience on our Site. Cookies are small files stored
            on your device that help us understand how you interact with our
            Site and remember your preferences. You can control cookie settings
            through your browser, but disabling cookies may affect your ability
            to use certain features of our Site.
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Data Retention:</strong> We retain your
            personal information only for as long as necessary to fulfill the
            purposes for which it was collected, including to provide you with
            services, comply with legal obligations, resolve disputes, and
            enforce our agreements. After this period, we will delete or
            anonymize your personal information.
          </li>
        </ul>

        <h2 style={heading2Style}>3. Use of Your Information</h2>
        <p style={paragraphStyle}>
          We use the information we collect in the following ways:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            To operate, maintain, and improve our Site and services.
          </li>
          <li style={listItemStyle}>
            To manage your account, including processing payments and fulfilling
            orders.
          </li>
          <li style={listItemStyle}>
            To send you administrative information, such as updates and changes
            to our terms, conditions, and policies.
          </li>
        </ul>

        <h2 style={heading2Style}>4. Disclosure of Your Information</h2>
        <p style={paragraphStyle}>
          We may share the information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>By Law or to Protect Rights:</strong> If
            we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and
            safety of others.
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Third-Party Service Providers:</strong>{" "}
            We may share your information with third parties that perform
            services for us or on our behalf, including payment processing, data
            analysis, email delivery, hosting services, customer service, and
            marketing assistance.We encourage you to review the privacy policies
            of any third-party sites you visit.We are not responsible for the
            privacy practices or content of these third parties.
          </li>
        </ul>

        <h2 style={heading2Style}>5. Security of Your Information</h2>
        <p style={paragraphStyle}>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>

        <h2 style={heading2Style}>6. Policy for Children</h2>
        <p style={paragraphStyle}>
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible.
        </p>

        <h2 style={heading2Style}>7. Changes to This Privacy Policy</h2>
        <p style={paragraphStyle}>
          We reserve the right to update or modify this Refund and Cancellation
          Policy at any time. Any changes will be posted on our website, and it
          is your responsibility to review the policy periodically for updates.
        </p>

        <h2 style={heading2Style}>8. Contact Us</h2>
        <p style={paragraphStyle}>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p style={contactStyle}>Email: console2success@gmail.com</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
