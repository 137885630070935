import React from "react";

const TermsConditions = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 768px)": {
      padding: "15px",
    },
    "@media (max-width: 480px)": {
      padding: "10px",
    },
  };

  const heading1Style = {
    color: "#2c3e50",
    fontSize: "2.5em",
    marginBottom: "20px",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "2em",
    },
    "@media (max-width: 480px)": {
      fontSize: "1.5em",
    },
  };

  const heading2Style = {
    color: "#3498db",
    fontSize: "1.5em",
    marginTop: "20px",
    marginBottom: "10px",
    "@media (max-width: 768px)": {
      fontSize: "1.2em",
    },
    "@media (max-width: 480px)": {
      fontSize: "1em",
    },
  };

  const paragraphStyle = {
    lineHeight: "1.6",
    marginBottom: "20px",
    color: "#666",
    fontSize: "1em",
    "@media (max-width: 768px)": {
      fontSize: "0.9em",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8em",
    },
  };

  const listStyle = {
    listStyleType: "disc",
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#666",
  };

  const listItemStyle = {
    marginBottom: "10px",
  };

  const contactStyle = {
    color: "#e74c3c",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <h1 style={heading1Style}>Terms and Conditions</h1>
      <p style={paragraphStyle}>Effective Date: 30 July 2024</p>

      <h2 style={heading2Style}>1. Introduction</h2>
      <p style={paragraphStyle}>
        By accessing this webpage, you are agreeing to be bound by these Terms
        and Conditions (“Terms") in a legally binding agreement between us
        (“Merchant”or“us” or “we”or“our” ) and the User (“you” or “your”).
        Please read these Terms carefully before accessing or using the Website.
        If you do not agree to the Terms, you may not access the Platform. We
        reserve the right to update and change the Terms and Conditions by
        posting updates and changes to the Platform. You are advised to check
        the Terms and Conditions from time to time for any updates or changes
        that may impact you. If at any point such amendments are not acceptable
        to you, we advise you to cease using the Platform at such time.
      </p>

      <h2 style={heading2Style}>2. User Accounts</h2>
      <p style={paragraphStyle}>
        By creating an account on our website, you agree to provide accurate and
        complete information and to keep this information up-to-date. You are
        responsible for maintaining the confidentiality of your account and
        password and for restricting access to your account.
      </p>

      <h2 style={heading2Style}>3. Use of Our Services</h2>
      <p style={paragraphStyle}>
        By using our services, you agree to use them only for lawful purposes
        and in a manner that does not infringe the rights of, restrict or
        inhibit anyone else's use and enjoyment of the services. Prohibited
        behavior includes harassing or causing distress or inconvenience to any
        other user, transmitting obscene or offensive content, or disrupting the
        normal flow of dialogue within our services.
      </p>

      <h2 style={heading2Style}>4. Termination</h2>
      <p style={paragraphStyle}>
        We reserve the right to terminate or suspend your account and access to
        our services at our sole discretion, without notice, for conduct that we
        believe violates these Terms or is harmful to other users of the
        services or to us.
      </p>
      <h2 style={heading2Style}>5. Intellectual Property</h2>
      <p style={paragraphStyle}>
        Unless otherwise stated, console2success.in and/or its licensors own the
        intellectual property rights for all material on the website. All
        intellectual property rights are reserved. You may access this from
        console2success.in for your own personal use subjected to restrictions
        set in these terms and conditions.
      </p>

      <h2 style={heading2Style}>6. Refund and Cancellation</h2>
      <p style={paragraphStyle}>
        Our refund and cancellation policy is described in detail on our Refund
        and Cancellation Policy page. Please refer to this page for more
        information on how we handle refunds and cancellations.
      </p>

      <h2 style={heading2Style}>7. Modifications to Terms</h2>
      <p style={paragraphStyle}>
        We may revise these terms and conditions from time to time. The most
        current version will always be posted on our website. By continuing to
        access or use our services after revisions become effective, you agree
        to be bound by the revised terms.
      </p>

      <h2 style={heading2Style}>8.Third-Party Links</h2>
      <p style={paragraphStyle}>
        Our services may contain links to third-party websites or services that
        are not owned or controlled by [Your Website Name]. We have no control
        over, and assume no responsibility for, the content, privacy policies,
        or practices of any third-party websites or services. You acknowledge
        and agree that [Your Website Name] shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused by or in
        connection with the use of or reliance on any such content, goods, or
        services available on or through any such websites or services.
      </p>
      <h2 style={heading2Style}>9. Contact Us</h2>
      <p style={paragraphStyle}>
        If you have any questions about these Terms and Conditions, please
        contact us at:
      </p>
      <p style={contactStyle}>Email: console2success@gmail.com</p>
    </div>
  );
};

export default TermsConditions;
