import React from "react";

const RefundPolicy = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 768px)": {
      padding: "15px",
    },
    "@media (max-width: 480px)": {
      padding: "10px",
    },
  };

  const heading1Style = {
    color: "#2c3e50",
    fontSize: "2.5em",
    marginBottom: "20px",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "2em",
    },
    "@media (max-width: 480px)": {
      fontSize: "1.5em",
    },
  };

  const heading2Style = {
    color: "#3498db",
    fontSize: "1.5em",
    marginTop: "20px",
    marginBottom: "10px",
    "@media (max-width: 768px)": {
      fontSize: "1.2em",
    },
    "@media (max-width: 480px)": {
      fontSize: "1em",
    },
  };

  const paragraphStyle = {
    lineHeight: "1.6",
    marginBottom: "20px",
    color: "#666",
    fontSize: "1em",
    "@media (max-width: 768px)": {
      fontSize: "0.9em",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8em",
    },
  };

  const listStyle = {
    listStyleType: "disc",
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#666",
  };

  const listItemStyle = {
    marginBottom: "10px",
  };

  const contactStyle = {
    color: "#e74c3c",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <h1 style={heading1Style}>Refund and Cancellation Policy</h1>
      <p style={paragraphStyle}>Effective Date: 30 July 2024</p>

      <h2 style={heading2Style}>1. Introduction</h2>
      <p style={paragraphStyle}>
        At console2success, we strive to provide the best educational resources
        to our users.
      </p>

      <h2 style={heading2Style}>2. Refunds</h2>
      <p style={paragraphStyle}>We doest not provide any refund in any case.</p>

      <h2 style={heading2Style}>3. Cancellations</h2>
      <p style={paragraphStyle}>
        You can cancel your subscription at any time by contacting us at
        console2success@gmail.com. Your cancellation will take effect at the end
        of the current billing period. <br />
        We don't entertain any Refund & Cancellation
      </p>

      {/* <h2 style={heading2Style}>4. Process for Refunds</h2>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          To complete your refund, we require a receipt or proof of purchase.
        </li>

        <li style={listItemStyle}>
          There are certain situations where only partial refunds are granted.
        </li>
      </ul> */}

      <h2 style={heading2Style}>4. Contact Us</h2>
      <p style={paragraphStyle}>
        If you have any questions about our Refund and Cancellation Policy,
        please contact us at:
      </p>
      <p style={contactStyle}>Email: console2success@gmail.com</p>
    </div>
  );
};

export default RefundPolicy;
